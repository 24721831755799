import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import NavBar from '../NavBar';
import Home from '../Home';
import Cookies from 'js-cookie';
import axios from 'axios';
import './Dashboard.css';

const Dashboard = () => {
  const [totalViews, setTotalViews] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [shortLinks, setShortLinks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const linksPerPage = 10;
  const [userId, setUserId] = useState('');
  const [fullName, setFullName] = useState('');
  const [sortOption, setSortOption] = useState('latest');
  const navigate = useNavigate();
  const BASE_URL = 'https://opnier.com/';

  useEffect(() => {
    // Retrieve userId and full_name from cookies
    const storedUserId = Cookies.get('userId');
    const storedFullName = Cookies.get('full_name');
    const storedReferral = Cookies.get('my_referral_code');
    
    if (!storedUserId) {
      navigate('/users/login');
      return; // Exit early if no userId
    }
    setUserId(storedUserId);
    setFullName(storedFullName);

    // Fetch total views and earnings
    axios.get(`https://api.opnier.com/get-dashboard-stats.php?uid=${storedUserId}&referral_code=${storedReferral}`)
      .then(response => {
        setTotalViews(response.data.totalViews);
        setTotalEarnings(response.data.totalEarnings);
        setTotalReferrals(response.data.totalReferrals);
      })
      .catch(error => console.log(error));

    // Fetch short links with pagination
    axios.get(`https://api.opnier.com/get-short-links.php?uid=${storedUserId}&page=${currentPage}&limit=${linksPerPage}&sort=${sortOption}`)
      .then(response => setShortLinks(response.data.links))
      .catch(error => console.log(error));
  }, [currentPage, sortOption]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleCopyInputValue = (link) => {
    const inputElement = document.createElement('input');
    inputElement.value = link;
    document.body.appendChild(inputElement);
    inputElement.select();
    document.execCommand("copy");
    document.body.removeChild(inputElement);
    alert("Short Link Copied, Enjoy!!");
  };

  const handleShareInputValue = async (link) => {
    const shareData = {
      title: 'Check this out!',
      text: 'A simple and easy to use Youtube App opener site.\n\n',
      url: link,
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
        console.log('Content shared successfully');
      } else {
        alert('Web Share API is not supported in your browser.');
      }
    } catch (err) {
      console.error('Error sharing:', err);
    }
  };

  return (
    <div>
      <NavBar />
      <div className="dashboard">
        <div className="pagination">
          <p>Welcome, {fullName}</p>
          <Link to='/users/logout' className="text-center button-like">
            Logout
          </Link>
        </div>
        <Home />
        <div className="stats-block">
          <div className="stat">
            <h3>Total Views</h3>
            <p>{totalViews ? totalViews : 0}</p>
          </div>
          <div className="stat">
            <h3>Total Referrals</h3>
            <p>{totalReferrals}</p> {/* New referral stats */}
          </div>
          <div className="stat">
            <h3>Total Earnings</h3>
            <p>&#8377;{totalEarnings.toFixed(2)}</p>
          </div>
        </div>

        <table className="links-table">
          <thead>
            <tr>
              <th>S. No.</th>
              <th>
                Smart Links &nbsp;
                <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
                  <option value="latest">Latest</option>
                  <option value="oldest">Oldest</option>
                  <option value="most_viewed">Most Viewed</option>
                  <option value="least_viewed">Least Viewed</option>
                </select>
              </th>
              <th className="s-views">Views</th>
              <th className="action">Actions</th>
            </tr>
          </thead>
          <tbody>
          {shortLinks.map((link, index) => (
            <tr key={link.id}>
              <td className="s-no">{(currentPage - 1) * linksPerPage + index + 1}</td>
              <td>{BASE_URL + link.short_link}</td>
              <td>{link.view_count}</td>
              <td>
                <button onClick={() => handleCopyInputValue(BASE_URL+link.short_link)}>Copy</button>
                <button className="btn-for-share" onClick={() => handleShareInputValue(BASE_URL+link.short_link)}>Share</button>
              </td>
            </tr>
          ))}
        </tbody>
        </table>

        <div className="pagination">
          <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
          <button onClick={() => paginate(currentPage + 1)}>Next</button>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
