import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Fetch from './Fetch';
import NotFound from './NotFound';
import Features from './Features';
import Login from './users/Login';
import ForgetPassword from './users/ForgetPassword';
import ResetPassword from './users/ResetPassword';
import Signup from './users/Signup';
import EmailVerification from './users/EmailVerification';
import Dashboard from './users/Dashboard';
import Logout from './users/Logout';
import About from './more/About';
import TermsAndConditions from './more/TermsAndConditions';
import PrivacyPolicy from './more/PrivacyPolicy';
import ReferAndEarn from './ReferAndEarn';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Route for the Fetch component with dynamic path */}
          <Route path="/:id" element={<Fetch />} />
          {/* Static routes */}
          <Route path="/" element={<Fetch />} />
          <Route path="/features" element={<Features />} />
          <Route path="/users/login" element={<Login />} />
          <Route path="/users/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/users/signup" element={<Signup />} />
          <Route path="/users/verify-email" element={<EmailVerification />} />
          <Route path="/users/dashboard" element={<Dashboard />} />
          <Route path="/users/logout" element={<Logout />} />
          <Route path="/more/about" element={<About />} />
          <Route path="/more/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/more/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refer-and-earn" element={<ReferAndEarn />} />
          {/* Catch-all route for 404 Not Found */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
