import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LandingPage from './LandingPage';
import NotFound from './NotFound';

// Function to open YouTube links in the app
function openYouTubeLink(fetchedUrl) {
    const isAndroid = /(android)/i.test(navigator.userAgent);
    const isIOS = /(iPad|iPhone|iPod)/i.test(navigator.userAgent);

    const { ytVideoId, ytChannelId, ytUserURL } = getVideoId(fetchedUrl);

    if (isAndroid) {
        if (ytVideoId) {
            window.location.href = `vnd.youtube://${ytVideoId}`;
        } else if (ytChannelId) {
            window.location.href = `vnd.youtube://channel/${ytChannelId}`;
        } else if (ytUserURL) {
            window.location.href = `vnd.youtube://@${ytUserURL}`;
        }
    } else if (isIOS) {
        if (ytVideoId) {
            window.location.href = `youtube://${ytVideoId}`;
        } else if (ytChannelId) {
            window.location.href = `youtube://youtube.com/channel/${ytChannelId}`;
        } else if (ytUserURL) {
            window.location.href = `youtube://youtube.com/@${ytUserURL}`;
        }
    } else {
        window.open(fetchedUrl, "_blank");
    }
}

// Function to open Instagram links in the app
function openInstagramLink(fetchedUrl) {
    const isAndroid = /(android)/i.test(navigator.userAgent);
    const isIOS = /(iPad|iPhone|iPod)/i.test(navigator.userAgent);

    const igProfileId = fetchedUrl.split('instagram.com/')[1].split('/')[0];
    

    if (isAndroid) {
        window.location.href = `vnd.instagram://user?username=${igProfileId}`;
    } else if (isIOS) {
        window.location.href = `instagram://user?username=${igProfileId}`;
    } else {
        window.open(fetchedUrl, "_blank");
    }
}

// Function to extract YouTube and Instagram IDs
function getVideoId(fetchedUrl) {
    let ytVideoId, ytChannelId, ytUserURL;

    // YouTube patterns
    const ytVideoPatterns = [
        /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/,
        /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]+)/,
        /(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]+)/,
    ];
    const channelPatterns = [
        /(?:https?:\/\/)?(?:www\.)?youtube\.com\/channel\/([a-zA-Z0-9_-]+)/,
        /(?:https?:\/\/)?(?:www\.)?youtube\.com\/c\/([a-zA-Z0-9_-]+)/,
    ];
    const userPatterns = [
        /(?:https?:\/\/)?(?:www\.)?youtube\.com\/@([a-zA-Z0-9_-]+)/,
    ];

    // Match YouTube IDs
    ytVideoPatterns.forEach(pattern => {
        const match = fetchedUrl.match(pattern);
        if (match && match[1]) {
            ytVideoId = match[1];
        }
    });

    channelPatterns.forEach(pattern => {
        const match = fetchedUrl.match(pattern);
        if (match && match[1]) {
            ytChannelId = match[1];
        }
    });

    userPatterns.forEach(pattern => {
        const match = fetchedUrl.match(pattern);
        if (match && match[1]) {
            ytUserURL = match[1];
        }
    });

    return { ytVideoId, ytChannelId, ytUserURL };
}

function Fetch() {
    const { id } = useParams();
    const [fetchedLink, setThisLink] = useState('');
    const [fetchedDomain, setThisDomain] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [timer, setTimer] = useState(3); // 3 seconds countdown timer

    useEffect(() => {
        async function fetchSavedLink() {
            if (!id) {
                setLoading(false);
                setError(false);
                return;
            }

            try {
                const response = await fetch(`https://api.opnier.com/getlink.php?id=${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch Saved link');
                }

                const data = await response.json();
                setThisLink(data.link);
                setThisDomain(data.domain);

                // Get user IP address and record view
                const ipResponse = await axios.get('https://api.ipify.org?format=json');
                const userIp = ipResponse.data.ip;

                const requestBody = {
                    id: id,
                    ipAddress: userIp,
                };

                await fetch('https://api.opnier.com/record-view.php', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestBody)
                });
            } catch (error) {
                setError(true);
            } finally {
                // Start countdown timer
                const timerInterval = setInterval(() => {
                    setTimer(prevTimer => {
                        if (prevTimer <= 1) {
                            clearInterval(timerInterval);
                            setLoading(false); // Stop loading after timer ends
                            return 0;
                        }
                        return prevTimer - 1;
                    });
                }, 1000);
            }
        }

        fetchSavedLink();
    }, [id]);

    useEffect(() => {
        if (fetchedLink) {
            if (fetchedDomain === "YouTube") {
                openYouTubeLink(fetchedLink);
            } else if (fetchedDomain === "Instagram") {
                openInstagramLink(fetchedLink);
            } else {
                window.open(fetchedLink, "_blank");
            }
        }
    }, [fetchedLink, fetchedDomain]);

    if (loading) {
        return <div className='App'>Redirecting in {timer}...</div>;
    }

    if (error) {
        return <div className='App'><NotFound /></div>;
    }

    // if (!fetchedLink) {
    //     return <LandingPage />;
    // }

    return <LandingPage />;
}

export default Fetch;
