import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import NavBar from '../NavBar';
import NotFound from '../NotFound';
import styles from './ResetPassword.module.css';

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false); // State to manage button disable
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const response = await fetch('https://api.opnier.com/reset-password.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, newPassword }),
      });

      if (response.ok) {
        setMessage('Password has been reset successfully. Redirecting to login...');
        setError('');
        setIsDisabled(true); // Disable the button on success
        setTimeout(() => {
          navigate('/users/login');
        }, 3000); // Redirect after 3 seconds
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Failed to reset password. Please try again.');
        setMessage('');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
      setMessage('');
    }
  };

  // If the token is not present, render the NotFound component
  if (!token) {
    return <NotFound />;
  }

  return (
    <div>
      <NavBar />
      <div className={styles.resetPasswordMain}>
        <div className={styles.resetPasswordContainer}>
          <h2 className={styles.resetPasswordHeader}>Reset Password</h2>
          <form onSubmit={handleSubmit} className={styles.resetPasswordForm}>
            <div className={styles.passInputDiv}>
              <input 
                type={showPassword ? "text" : "password"} 
                placeholder="New Password" 
                value={newPassword} 
                className={styles.resetPasswordPassword}
                onChange={(e) => setNewPassword(e.target.value)} 
                required 
                disabled={isDisabled} // Disable input if the button is disabled
              />
              <input 
                type={showPassword ? "text" : "password"} 
                placeholder="Confirm Password" 
                value={confirmPassword} 
                className={styles.resetPasswordPassword}
                onChange={(e) => setConfirmPassword(e.target.value)} 
                required 
                disabled={isDisabled} // Disable input if the button is disabled
              />
              {showPassword ? 
                <FaEyeSlash onClick={() => setShowPassword(!showPassword)} /> :
                <FaEye onClick={() => setShowPassword(!showPassword)} />}
              <button 
                type="submit" 
                className={styles.resetPasswordButton}
                disabled={isDisabled} // Disable button when isDisabled is true
              >
                {isDisabled ? 'Password Reset' : 'Reset Password'}
              </button>
            </div>
          </form>
          {message && <div className={styles.resetPasswordSuccessMessage}>{message}</div>}
          {error && <div className={styles.resetPasswordErrorMessage}>{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
