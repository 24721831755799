import React from 'react';
import NavBar from '../NavBar';
import styles from '../assets/About.module.css';

const About = () => {
    return (
      <div>
        <NavBar />
        <div className={styles.aboutContainer}>
            <h1 className={styles.heading}>About</h1>

            <p className={styles.text}>
                Welcome to <strong>Opnier</strong>, the platform where you can monetize your online presence by creating smart links for YouTube videos, Youtube channel, Instagram profile and more coming soon. Our mission is to empower users with a simple and effective way to earn money online, whether you're sharing content on social media, a blog, or any other platform.
            </p>

            <h2 className={styles.subheading}>Our Vision</h2>
            <p className={styles.text}>
                At Opnier, we believe in creating opportunities for everyone. In a world where digital content is king, we aim to help you leverage your content sharing to generate income effortlessly. Our vision is to become the go-to platform for individuals looking to turn their online activity into a profitable venture.
            </p>

            <h2 className={styles.subheading}>How It Works</h2>
            <p className={styles.text}>
                Opnier makes it easy for you to start earning. Simply create smart links for YouTube videos, Youtube channel, Instagram profile and more (coming soon). And when someone clicks on your link, you earn money. It's that simple! Our system tracks every click, and you can monitor your earnings in real-time through our user-friendly dashboard.
            </p>

            <h2 className={styles.subheading}>Referral Program</h2>
            <p className={styles.text}>
                We also offer a lucrative referral program. Invite your friends to join Opnier, and earn upto &#8377;200 and your friend will get upto &#8377;50 instantly. It's a great way to boost your income by helping others discover the benefits of our platform.
            </p>

            <h2 className={styles.subheading}>Why Choose Opnier?</h2>
            <ul className={styles.list}>
                <li><strong>User-Friendly Interface:</strong> Our platform is designed with simplicity in mind, making it easy for anyone to start earning.</li>
                <li><strong>Real-Time Analytics:</strong> Track your links, clicks, and earnings all in one place with our detailed analytics.</li>
                <li><strong>Mobile Optimization:</strong> Manage your account on the go, with full mobile compatibility.</li>
                <li><strong>Secure and Reliable:</strong> We prioritize your security, ensuring all your data and transactions are protected.</li>
                <li><strong>Fast Payouts:</strong> We offer quick and reliable payout options, so you can enjoy your earnings without delay.</li>
            </ul>

            <h2 className={styles.subheading}>Join the Opnier Community</h2>
            <p className={styles.text}>
                Whether you're a content creator, influencer, or just someone looking to earn extra income online, Opnier is the perfect platform for you. Join our growing community of users who are already turning their clicks into cash.
            </p>
        </div>
      </div>
    );
};

export default About;
