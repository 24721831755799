import React, { Component } from 'react';
import Cookies from 'js-cookie';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputLink: '',
      linkerShort: '',
      isSubmitting: false,
      errorMessage: ''
    };
  }

  handleInputChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    const { inputLink } = this.state;
    let storedUserId = Cookies.get('userId');

    // If userId is not found, set it to NILL
    storedUserId = storedUserId ? storedUserId : 'NILL';

    // Determine the platform (YouTube or Instagram)
    let domain = '';
    if (inputLink.includes('youtube.com') || inputLink.includes('youtu.be')) {
      domain = 'YouTube';
    } else if (inputLink.includes('instagram.com')) {
      domain = 'Instagram';
    }

    // Check if the provided link is valid
    const isInputLink = this.isValidLink(inputLink);

    if (!isInputLink) {
      this.setState({ errorMessage: 'Please provide a valid link.' });
      return;
    }

    // Construct the request body without isHuman
    const requestBody = {
      inputLink,
      userId: storedUserId,
      domain,
    };

    this.setState({ isSubmitting: true, errorMessage: '' }); // Set submitting state to true

    fetch('https://api.opnier.com/putlink.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to create short link');
      }
      return response.text();
    })
    .then(linkerShort => {
      // Update linkerShort state variable
      this.setState({ linkerShort, isSubmitting: false }); // Reset submitting state
    })
    .catch(error => {
      this.setState({ isSubmitting: false }); // Reset submitting state on error
    });
  };

  isValidLink = link => {
    if (link.includes('@') && !link.includes('youtube.com/@')) {
      return false;
    }
    
    const testLinkRegex = /(?:https?:\/\/)?(?:www\.)?(youtube\.com\/watch\?v=([a-zA-Z0-9_-]+))|(youtu\.be\/([a-zA-Z0-9_-]+))|(youtube\.com\/@([a-zA-Z0-9_-]+))|(youtube\.com\/shorts\/([a-zA-Z0-9_-]+))|(youtube\.com\/channel\/([a-zA-Z0-9_-]+))|(youtube\.com\/c\/([a-zA-Z0-9_-]+))|(instagram\.com\/([a-zA-Z0-9._]+))/;
    return testLinkRegex.test(link);
  };

  handleCopyInputValue = () => {
    const inputElement = document.getElementById("opnierInput");
    if (inputElement) {
      inputElement.select();
      document.execCommand("copy");
      alert("Short Link Copied, Enjoy!!");
    }
  };

  handleShareInputValue = async () => {
    const shareUrl =  document.getElementById("opnierInput").value;
    const shareData = {
      title: 'Check this out!',
      text: 'A simple and easy to use App opener site, Helps you to Earn money.\n\n',
      url: shareUrl,
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        alert('Web Share API is not supported in your browser.');
      }
    } catch (err) {
      console.error('Error sharing:', err);
    }
  };

  render() {
    const { inputLink, linkerShort, isSubmitting, errorMessage } = this.state;

    return (
      <div>
        <div className='mt-5'></div>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-11'>
              <form onSubmit={this.handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="inputLink" className="form-label">Paste your link below:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputLink"
                    name="inputLink"
                    aria-describedby="textHelp"
                    value={inputLink}
                    onChange={this.handleInputChange}
                    required
                  />
                  <div id="textHelp" className="form-text">Generate smart URL and share with everyone.</div>
                </div>

                <button type="submit" className="btn btn-primary" disabled={isSubmitting || !inputLink.trim()}>Create Opnier link</button>
                {errorMessage && <div className="text-danger">{errorMessage}</div>}
              </form>
              {linkerShort && (
                <div className="mt-3">
                  <hr/>
                  <label htmlFor="opnierInput" className="form-label">Opnier short Link:</label>
                  <div className="input-group">
                    <input type="text" className="form-control" value={`https://opnier.com/${linkerShort}`} readOnly id="opnierInput" />
                    <button className="btn btn-secondary" type="button" onClick={this.handleCopyInputValue}>Copy</button>
                    <button className="btn btn-secondary hide-on-desktop" type="button" onClick={this.handleShareInputValue}>Share</button>
                  </div>
                  <small className="form-text text-muted">Copy and share this smart link & Earn Money.</small>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
