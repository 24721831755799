import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../NavBar';
import styles from '../assets/PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
    return (
        <div>
          <NavBar />
            <div className={styles.privacyContainer}>
                <h1 className={styles.heading}>Privacy Policy</h1>

                <h2 className={styles.subheading}>1. Introduction</h2>
                <p className={styles.text}>
                    At <strong>Opnier</strong>, we are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our website, <a href="https://opnier.com" target="_blank" rel="noopener noreferrer">opnier.com</a>.
                    By using our website, you consent to the collection and use of your information as described in this policy. If you do not agree with our practices, please do not use our website.
                </p>

                <h2 className={styles.subheading}>2. Information We Collect</h2>
                <p className={styles.text}>
                    We may collect the following types of information:
                </p>
                <ul className={styles.list}>
                    <li><strong>Personal Information:</strong> When you register an account or use our services, we collect personal information such as your name, email address, and any other information you provide.</li>
                    <li><strong>Usage Data:</strong> We collect information about how you interact with our website, including your IP address, browser type, pages visited, and the time and date of your visits.</li>
                    <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to track your activity on our website and to enhance your user experience.</li>
                </ul>

                <h2 className={styles.subheading}>3. How We Use Your Information</h2>
                <p className={styles.text}>
                    We use the information we collect for the following purposes:
                </p>
                <ul className={styles.list}>
                    <li><strong>To Provide and Improve Our Services:</strong> We use your information to deliver and enhance our services, including processing transactions and monitoring the performance of our website.</li>
                    <li><strong>To Communicate with You:</strong> We may use your contact information to send you updates, newsletters, or promotional materials related to our services. You can opt-out of these communications at any time.</li>
                    <li><strong>To Ensure Security and Compliance:</strong> We use your information to protect against unauthorized access and to comply with legal requirements.</li>
                </ul>

                <h2 className={styles.subheading}>4. Sharing Your Information</h2>
                <p className={styles.text}>
                    We do not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:
                </p>
                <ul className={styles.list}>
                    <li><strong>With Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing or email communication.</li>
                    <li><strong>For Legal Reasons:</strong> We may disclose your information if required by law or in response to legal requests, or if we believe disclosure is necessary to protect our rights or comply with legal obligations.</li>
                    <li><strong>In Case of Business Transfers:</strong> In the event of a merger, acquisition, or other business transfer, your information may be transferred as part of the transaction.</li>
                </ul>

                <h2 className={styles.subheading}>5. Data Security</h2>
                <p className={styles.text}>
                    We implement reasonable security measures to protect your information from unauthorized access, disclosure, or destruction. However, no data transmission over the internet or electronic storage method is completely secure, and we cannot guarantee absolute security.
                </p>

                <h2 className={styles.subheading}>6. Your Rights</h2>
                <p className={styles.text}>
                    You have the following rights regarding your personal information:
                </p>
                <ul className={styles.list}>
                    <li><strong>Access and Correction:</strong> You have the right to access and correct your personal information held by us.</li>
                    <li><strong>Deletion:</strong> You may request the deletion of your personal information, subject to certain legal exceptions.</li>
                    <li><strong>Opt-Out:</strong> You can opt-out of receiving marketing communications from us by following the unsubscribe instructions in the emails or contacting us directly.</li>
                </ul>

                <h2 className={styles.subheading}>7. Cookies and Tracking Technologies</h2>
                <p className={styles.text}>
                    Cookies are small files placed on your device that help us track your preferences and activities on our website. You can manage your cookie preferences through your browser settings. Please note that disabling cookies may affect your ability to use certain features of our website.
                </p>

                <h2 className={styles.subheading}>8. Changes to This Privacy Policy</h2>
                <p className={styles.text}>
                    We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the updated policy on our website. Your continued use of our website after any changes indicates your acceptance of the revised policy.
                </p>

                <h2 className={styles.subheading}>9. Contact Us</h2>
                <p className={styles.text}>
                    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <Link to="mailto:support@opnier.com">support@opnier.com</Link>.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
