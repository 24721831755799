import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import NavBar from '../NavBar';
import Image from "../assets/image.png";
import Logo from "../assets/logo.png";
//import GoogleSvg from "../assets/icons8-google.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Cookies from 'js-cookie';
import styles from './Signup.module.css'; // Import CSS module

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [full_name, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false); // State to manage button disable
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the user is already logged in
    const userId = Cookies.get('userId');
    if (userId) {
      navigate('/users/dashboard');
    }

    // Extract referral code from URL if present
    const queryParams = new URLSearchParams(location.search);
    const refCode = queryParams.get('ref');
    if (refCode) {
      setReferralCode(refCode);
    }
  }, [navigate, location]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    const lowerCaseEmail = email.toLowerCase();
    const lowerCaseReferralCode = referralCode.toLowerCase();

    try {
      const response = await fetch('https://api.opnier.com/signup.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: lowerCaseEmail, full_name, password, referralCode: lowerCaseReferralCode }),
      });

      if (response.ok) {
        setIsDisabled(true); // Disable button on success
        setError('');
        // Optionally handle success (e.g., show a message or redirect)
        navigate('/users/verify-email?signup=success'); // Redirect to login page after successful signup
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Signup failed. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div>
      <NavBar />
      <div className={styles.signupMain}>
        <div className={styles.signupLeft}>
          <img src={Image} alt="" />
        </div>
        <div className={styles.signupRight}>
          <div className={styles.signupRightContainer}>
            <div className={styles.signupLogo}>
              <img src={Logo} loading="lazy" alt="" />
            </div>
            <div className={styles.signupCenter}>
              <h2>Sign-up & Earn!</h2>
              <p>Please enter your details</p>
              <form onSubmit={handleSubmit} className={styles.formL}>
                <input 
                  type="text" 
                  placeholder="Full Name" 
                  id="full_name" 
                  value={full_name} 
                  onChange={(e) => setFullName(e.target.value)} 
                  disabled={isDisabled} // Disable input if the button is disabled
                />
                <input 
                  type="email" 
                  id="email" 
                  value={email} 
                  placeholder="Email" 
                  onChange={(e) => setEmail(e.target.value)} 
                  disabled={isDisabled} // Disable input if the button is disabled
                />
                <div className={styles.passInputDiv}>
                  <input 
                    id="password" 
                    value={password} 
                    type={showPassword ? "text" : "password"} 
                    placeholder="Password" 
                    onChange={(e) => setPassword(e.target.value)} 
                    disabled={isDisabled} // Disable input if the button is disabled
                  />
                  <input 
                    id="confirmPassword" 
                    value={confirmPassword} 
                    type={showPassword ? "text" : "password"} 
                    placeholder="Confirm Password" 
                    onChange={(e) => setConfirmPassword(e.target.value)} 
                    disabled={isDisabled} // Disable input if the button is disabled
                  />
                  {showPassword ? 
                    <FaEyeSlash onClick={() => setShowPassword(!showPassword)} /> :
                    <FaEye onClick={() => setShowPassword(!showPassword)} />}
                </div>
                <input 
                  type="text" 
                  placeholder="Referral Code (optional)" 
                  id="referral_code" 
                  value={referralCode} 
                  onChange={(e) => setReferralCode(e.target.value)} 
                  disabled={isDisabled} // Disable input if the button is disabled
                />
                <div className={styles.signupCenterOptions}>
                  <div className={styles.rememberDiv}>
                    <input 
                      type="checkbox" 
                      id="remember-checkbox" 
                      required 
                      disabled={isDisabled} // Disable checkbox if the button is disabled
                    />
                    <label htmlFor="remember-checkbox">
                      Agree to our <Link to="/more/terms-and-conditions">terms & conditions</Link>.
                    </label>
                  </div>
                </div>
                {error && <div className="text-danger">{error}</div>}
                <div className={styles.signupCenterButtons}>
                  <button 
                    type="submit" 
                    disabled={isDisabled} // Disable button when isDisabled is true
                  >
                    {isDisabled ? 'Signing Up...' : 'Sign up'}
                  </button>
                  {/**<button type="button">
                    <img src={GoogleSvg} alt="" />
                    Log In with Google
                  </button>**/}
                </div>
              </form>
            </div>
            <p className={styles.signupBottomP}>
              Already have an account? <Link to="/users/login">Login</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
