import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

function NavBar() {
  // Check if user is logged in
  const userId = Cookies.get('userId');

  // State to manage navbar toggling
  const [isNavOpen, setIsNavOpen] = useState(false);

  // Ref to navbar for click outside detection
  const navRef = useRef(null);

  // Get current location
  const location = useLocation();

  // Toggle navbar visibility
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  // Close navbar when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsNavOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Helper function to determine if the link is active
  const isActive = (path) => location.pathname === path ? 'active' : '';

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-dark" data-bs-theme="dark" ref={navRef}>
        <div className="container-fluid">
          <div className="d-flex w-100 justify-content-between">
            <Link className="navbar-brand" to="/">App Opnier</Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded={isNavOpen}
              aria-label="Toggle navigation"
              onClick={toggleNav}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className={`nav-link ${isActive('/')}`} aria-current="page" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${isActive('/features')}`} to="/features">Features</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${isActive('/more/about')}`} to="/more/about">About</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${isActive('/refer-and-earn')}`} to="/refer-and-earn">Refer & Earn</Link>
              </li>
              {userId ? (
                <li className="nav-item">
                  <Link className={`nav-link ${isActive('/users/dashboard')}`} to="/users/dashboard">Dashboard</Link>
                </li>
              ) : (
                <li className="nav-item">
                  <Link className={`nav-link ${isActive('/users/login') || isActive('/users/signup')}`} to="/users/login">Login / Signup</Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
