import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavBar from './NavBar';

export class NotFound extends Component {
  render() {
    return (
      <div>
        <NavBar />
        <div className="container mt-5">
          <h1 className="text-center">404 - Not Found</h1>
          <p className="text-center">Sorry, The page you're looking for does not exist.</p>
          <p className="text-center"><Link to='/'>Back to home safely.</Link></p>
        </div>
      </div>
    )
  }
}

export default NotFound
