import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from './NavBar';
import styles from './assets/Features.module.css';

const Features = () => {
    return (
      <div>
        <NavBar />
        <div className={styles.featuresContainer}>
            <h1 className={styles.heading}>Features</h1>

            <div className={styles.feature}>
                <h2 className={styles.subheading}>Earn Money by Creating Smart Links</h2>
                <p>
                    Opnier allows you to generate smart links for YouTube videos & Youtube channel, that directly open in the YouTube app, Also Instagram profile that directly open in the Instagram app and more coming soon. Each time someone clicks on your link, you earn money. The more clicks your links get, the more you can earn. You will get &#8377;10 for every 1000 clicks. It's a simple and efficient way to monetize your online presence.
                </p>
            </div>

            <div className={styles.feature}>
                <h2 className={styles.subheading}>Referral Program</h2>
                <p>
                    Boost your earnings by referring friends to Opnier. Share your unique referral link, and when your friends join and start creating smart links, you earn upto &#8377;200 and your friend will get upto &#8377;50 instantly. It's a win-win for both you and your friends!
                </p>
            </div>

            <div className={styles.feature}>
                <h2 className={styles.subheading}>Easy-to-Use Dashboard</h2>
                <p>
                    Our intuitive dashboard provides you with real-time statistics on your smart links. Track the number of clicks, your total earnings, and manage all your links in one place. We've designed the interface to be user-friendly, so you can focus on growing your earnings.
                </p>
            </div>

            <div className={styles.feature}>
                <h2 className={styles.subheading}>Mobile-Friendly</h2>
                <p>
                    Opnier is optimized for mobile devices, ensuring that you can manage your links and earnings on the go. Whether you're at home or on the move, you have full control over your account.
                </p>
            </div>

            <div className={styles.feature}>
                <h2 className={styles.subheading}>Secure and Reliable</h2>
                <p>
                    We prioritize your security. All transactions and data are encrypted, ensuring your information is safe. With Opnier, you can trust that your earnings and personal data are protected.
                </p>
            </div>

            <div className={styles.feature}>
                <h2 className={styles.subheading}>Fast Payouts</h2>
                <p>
                    No long waits to receive your earnings. Opnier offers fast and reliable payout options, so you can withdraw your money once you reach &#8377;1000 in your account, quickly and conveniently to your Bank account / UPI.
                </p>
            </div>

            <div className={styles.feature}>
                <h2 className={styles.subheading}>24/7 Support</h2>
                <p>
                    We're here to help you succeed. Our dedicated support team is available 24/7 to assist you with any questions or issues you might have. Whether you need help with creating links, understanding your earnings, or anything else, we're just a message away, please contact us at <Link to="mailto:support@opnier.com">support@opnier.com</Link>.
                </p>
            </div>
        </div>
      </div>
    );
};

export default Features;
