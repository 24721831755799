import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavBar from '../NavBar';
import styles from './ForgetPassword.module.css';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false); // State to manage button disable

  const handleSubmit = async (event) => {
    event.preventDefault();

    const lowerCaseEmail = email.toLowerCase();

    try {
      const response = await fetch('https://api.opnier.com/forget-password.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: lowerCaseEmail }),
      });

      if (response.ok) {
        setMessage('A password reset link has been sent to your email.');
        setError('');
        setIsDisabled(true); // Disable the button on success
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Failed to send reset link. Please try again.');
        setMessage('');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
      setMessage('');
    }
  };

  return (
    <div>
      <NavBar />
      <div className={styles.forgetPasswordMain}>
        <div className={styles.forgetPasswordContainer}>
          <h2 className={styles.forgetPasswordHeader}>Forgot Password</h2>
          <p className={styles.forgetPasswordPara}>Enter your email address to reset your password.</p>
          <form onSubmit={handleSubmit} className={styles.forgetPasswordForm}>
            <input 
              type="email" 
              placeholder="Email" 
              value={email} 
              className={styles.forgetPasswordInput}
              onChange={(e) => setEmail(e.target.value)} 
              required 
              disabled={isDisabled} // Disable input if the button is disabled
            />
            <button 
              className={styles.forgetPasswordButton} 
              type="submit" 
              disabled={isDisabled} // Disable button when isDisabled is true
            >
              {isDisabled ? 'Link Sent' : 'Send Reset Link'}
            </button>
          </form>
          {message && <div className={styles.forgetPasswordSuccessMessage}>{message}</div>}
          {error && <div className={styles.forgetPasswordErrorMessage}>{error}</div>}
          <p className={styles.forgetPasswordPara}>
            Remembered your password? <Link to="/users/login" className={styles.forgetPasswordLink}>Login</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
