import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NavBar from '../NavBar';

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('Verifying...');
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get('token');
    const signup = searchParams.get('signup');

    if (signup === "success") {
      setStatus(
        <>
          <h2>Sign-up successful</h2>
          <p>
            Check your inbox for the verification link from <strong>no-reply@opnier.com</strong>
          </p>
          <p>
            *<em>If you didn’t receive the email, please check your spam folder.</em>
          </p>
        </>
      );
      return;
    } else if (!token) {
      setStatus(
        <>
          <h2>Email Verification</h2>
          <p>Invalid or missing token.</p>
        </>
      );
      return;
    }

    const verifyEmail = async () => {
      try {
        const response = await fetch(`https://api.opnier.com/verify-email.php?token=${token}`);
        
        if (response.ok) {
          setStatus(
            <>
              <h2>Email Verification</h2>
              <p>Email verified successfully! Redirecting to login...</p>
            </>
          );
          setTimeout(() => {
            navigate('/users/login');
          }, 2000); // Redirect after 2 seconds
        } else {
          const errorData = await response.json();
          setStatus(errorData.error || 
            <>
              <h2>Email Verification</h2>
              <p>Failed to verify email. Please try again.</p>
            </>
          );
        }
      } catch (error) {
        setStatus(
          <>
            <p>An error occurred. Please try again.</p>
          </>
        );
      }
    };

    verifyEmail();
  }, [searchParams, navigate]);

  return (
    <div>
        <NavBar />
        <div className='mt-5'></div>
            <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-11'>
                    {status}
                </div>
            </div>
        </div>
    </div>
  );
};

export default EmailVerification;
