import React from 'react';
import Cookies from 'js-cookie';
import NavBar from './NavBar';
import Home from './Home';
import ImageCrousel from './ImageCrousel';

function LandingPage() {
  const userId = Cookies.get('userId');
  
  return (
    <div>
      <NavBar />
      <Home />
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-11'>
            <br/>
            {!userId && <p>Login and Earn Real money.</p>}
          </div>
        </div>
      </div>
      <ImageCrousel />
    </div>
  );
}

export default LandingPage;
