import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../NavBar';
import styles from '../assets/TermsAndConditions.module.css';

const TermsAndConditions = () => {
    return (
        <div>
          <NavBar />
            <div className={styles.termsContainer}>
                <h1 className={styles.heading}>Terms and Conditions</h1>

                <h2 className={styles.subheading}>1. Introduction</h2>
                <p className={styles.text}>
                    Welcome to <strong>Opnier</strong>. These terms and conditions outline the rules and regulations for the use of Opnier's website, located at <a href="https://opnier.com" target="_blank" rel="noopener noreferrer">opnier.com</a>.
                    By accessing this website, we assume you accept these terms and conditions. Do not continue to use Opnier if you do not agree to all the terms and conditions stated on this page.
                </p>

                <h2 className={styles.subheading}>2. Use of the Website</h2>
                <p className={styles.text}>
                    By using Opnier, you warrant that you are at least 18 years of age or are accessing the site under the supervision of a parent or legal guardian. You also agree to use the site for lawful purposes only and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use of the website.
                </p>

                <h2 className={styles.subheading}>3. Account Registration</h2>
                <p className={styles.text}>
                    To access certain features of the website, you may need to register an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your password and for all activities that occur under your account.
                </p>

                <h2 className={styles.subheading}>4. Earnings and Payments</h2>
                <p className={styles.text}>
                    Opnier allows users to earn money by creating and sharing short links. Earnings are calculated based on the number of valid clicks on these links. All payments are subject to verification and are made according to the payout schedule provided on the website. Opnier reserves the right to withhold payment if fraudulent activity is suspected.
                </p>

                <h2 className={styles.subheading}>5. Referral Program</h2>
                <p className={styles.text}>
                    Opnier offers a referral program that allows users to earn a percentage of the earnings of users they refer to the platform. The terms of the referral program, including the percentage of earnings and eligibility criteria, are subject to change at the discretion of Opnier.
                </p>

                <h2 className={styles.subheading}>6. Prohibited Activities</h2>
                <p className={styles.text}>
                    Users agree not to engage in any of the following prohibited activities:
                </p>
                <ul className={styles.list}>
                    <li>Using the website for any illegal purpose or to solicit others to perform or participate in any unlawful acts.</li>
                    <li>Circumventing or attempting to circumvent any security measures on the website.</li>
                    <li>Using automated systems or software to extract data from the website for commercial purposes.</li>
                    <li>Creating false or misleading links, or engaging in click fraud.</li>
                </ul>

                <h2 className={styles.subheading}>7. Intellectual Property</h2>
                <p className={styles.text}>
                    All content on Opnier, including but not limited to text, graphics, logos, and software, is the property of Opnier or its content suppliers and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works based on this content without the explicit permission of Opnier.
                </p>

                <h2 className={styles.subheading}>8. Termination</h2>
                <p className={styles.text}>
                    Opnier reserves the right to terminate your account or restrict your access to the website at any time, without notice, if we believe you have violated these terms and conditions or for any other reason at our sole discretion. You can also read our <Link to="/more/privacy-policy">Privacy Policy</Link>.
                </p>

                <h2 className={styles.subheading}>9. Limitation of Liability</h2>
                <p className={styles.text}>
                    To the fullest extent permitted by law, Opnier shall not be liable for any damages resulting from the use of, or inability to use, the website, or from any content posted on the website by Opnier or its users. This includes, without limitation, incidental and consequential damages, lost profits, or damages resulting from lost data or business interruption.
                </p>

                <h2 className={styles.subheading}>10. Governing Law</h2>
                <p className={styles.text}>
                    These terms and conditions are governed by and construed in accordance with the laws of India, and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
                </p>

                <h2 className={styles.subheading}>11. Changes to Terms</h2>
                <p className={styles.text}>
                    Opnier reserves the right to modify these terms and conditions at any time. Any changes will be effective immediately upon posting on the website. Your continued use of the website following the posting of revised terms means that you accept and agree to the changes.
                </p>

                <h2 className={styles.subheading}>12. Contact Information</h2>
                <p className={styles.text}>
                    If you have any questions about these Terms and Conditions, please contact us at <Link to="mailto:support@opnier.com">support@opnier.com</Link>.
                </p>
            </div>
        </div>
    );
};

export default TermsAndConditions;
