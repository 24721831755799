import React from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear user session or authentication data
    Cookies.remove('userId');
    Cookies.remove('full_name');
    Cookies.remove('my_referral_code');
    Cookies.remove('authToken');

    // Redirect to the login page
    navigate('/users/login');
  };

  React.useEffect(() => {
    handleLogout();
  }, [navigate]);

  return (
    <div>
      <h1>Logging Out...</h1>
      <p>Please wait while we log you out.</p>
    </div>
  );
};

export default Logout;
